import React, { useState, useEffect } from "react";
import "./TripAssistance.css";
import background from "../assets/map-bg.png";
import { SERVER_URL } from "../common/common_var";
import isEmpty from "lodash.isempty";
import Swal from 'sweetalert2'

export default function TripAssistance(props) {
  const [mainData, setMainData] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    fetchJobDetails();
  }, []);

  const fetchJobDetails = () => {
    fetch(`${SERVER_URL}/api/tripassistances/${props.match.params.id}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data && !data.message) {
          setMainData(data.assign);
          setIsConfirm(data.status === "pending" ? false : true);
        } else {
          props.history.push("/expire");
        }
      })
      .catch((error) => {
        this.props.history.push("/expire");
        throw error;
      });
  };

  const updateTrip = (isConfirm) => {

    Swal.fire({
      title: 'Alert',
      text: "Are you sure that you require a return ride right now? The driver may not be able to wait outside for long.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Later'
    }).then((result) => {
      if (result.isConfirmed) {
        callUpdateApi(isConfirm);
      }
    })

  };

  const callUpdateApi = (isConfirm) => {

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        status: !isConfirm ? 'confirmed' : 'pending',
      }),
    };
    fetch(`${SERVER_URL}/api/tripassistances/${props.match.params.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && !data.message) {
          setMainData(data.assign);
          setIsConfirm(data.status === "pending" ? false : true);
        } else {
          props.history.push("/expire");
        }
      }).catch(error => {
        this.props.history.push("/expire");
        throw error;
      });
  }


  return (
    !isEmpty(mainData) && (
      <section
        className="relative h-screen bg-no-repeat bg-cover bg-bottom bg-hidden"
        style={{ backgroundImage: `url(${background})` }}
      // style={{ backgroundColor: 'red' }}
      >
        <div className="absolute top-0 w-full p-4">
          <div className="bg-gray-50 w-full rounded-xl shadow-lg max-w-3xl mx-auto">
            <div className="rounded-t-xl bg-themebgdark px-3 py-2 text-gray-500 text-lg">
              Hi{" "}
              <span className="font-bold">
                {mainData.priorityClient?.displayName}
              </span>
            </div>
            <div className="p-3">
              <div className="text-gray-500 text-md">
                {!isConfirm ? "When" : ""} You're ready to be picked up from
              </div>
              <div className="flex mt-3">
                <div className="flex-shrink-0 h-12 w-12 border-2 border-red-300 flex items-center justify-center p-1 bg-red-600 mr-3">
                  <div className="">
                    <svg
                      className="devfont devfont-map-pin h-6 w-6 text-white stroke-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path d="M12 12.75a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                      <path d="M20 9.692C20 16.615 12 22 12 22S4 16.615 4 9.692c0-2.04.843-3.996 2.343-5.439S9.878 2 12 2s4.157.81 5.657 2.253S20 7.653 20 9.693z"></path>
                    </svg>
                  </div>
                </div>
                <div className="text-md text-gray-500 place-self-center">
                  {mainData?.jobDestinationAddress}
                </div>
              </div>
              {!isConfirm && (
                <div className="mt-4 flex flex-col">
                  <div className="text-xs italic  text-gray-500 text-center">
                    Please tap the button below
                  </div>
                  <div className="flex justify-center mt-1 stroke-2">
                    <svg
                      className="devfont devfont-chevrons-down h-4 w-4 text-gray-500 animate-pulse"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path d="M7 6l5 5 5-5"></path>
                      <path d="M7 13l5 5 5-5"></path>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!isConfirm && (
            <div
              onClick={() => updateTrip(isConfirm)}
              className="cursor-pointer flex h-14 mt-2 rounded-l-xl rounded-r-xl items-center max-w-3xl mx-auto shadow-lg"
            >
              <div className="w-16 h-full flex items-center justify-center bg-themegreendark flex-shrink-0 rounded-l-xl">
                <svg
                  className="devfont devfont-checks h-6 w-6 text-white stroke-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <path d="M7 12l5 5L22 7"></path>
                  <path d="M2 12l5 5"></path>
                  <path d="M12 12l5-5"></path>
                </svg>
              </div>
              <div className="text-xs text-white bg-theme4 bg-themegreenlight h-full w-full flex items-center rounded-r-xl px-3 py-2">
                Yes, I'm ready! Please pick me up
              </div>
            </div>
          )}
          {isConfirm && (
            <div className="mt-4 max-w-3xl mx-auto">
              <div className="h-20 w-20 rounded-full flex items-center justify-center bg-themebglight border shadow-lg mx-auto">
                <svg
                  className="devfont devfont-checks h-8 w-8 text-themegreendark stroke-2 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <path d="M7 12l5 5L22 7"></path>
                  <path d="M2 12l5 5"></path>
                  <path d="M12 12l5-5"></path>
                </svg>
              </div>
              <div className="text-lg font-bold text-themegreendark mt-2 text-center">
                Marked Ready!
              </div>
            </div>
          )}
        </div>
      </section>
    )
  );
}
