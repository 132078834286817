
exports.addMarker = (markers,map,maps,latLng,lable,markerIconUrl) => {
  
    markers.push(
      new maps.Marker({
        animation:  window.google.maps.Animation.DROP,
        position: latLng,
        label: {
          color: 'gray',
          fontWeight: 'bold',
          text: lable,
        },
        icon: {
          labelOrigin: new window.google.maps.Point(25, 60),
          url: markerIconUrl,
          scaledSize: new window.google.maps.Size(50, 50),
          // size: new window.google.maps.Size(22, 40),
          // origin: new window.google.maps.Point(0, 0),
          // anchor: new window.google.maps.Point(0, 0),
        },
        map,
      })
    );
}
exports.addMarkerInfo = (infoArray,maps,bodyText) => {
  infoArray.push(
    new maps.InfoWindow({
      content: this.getInfoWindowString(bodyText),
    })
  );
}

exports.drawRoute = (data,map,start,end) => {
  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer({
    // Remove default route marker
    suppressMarkers: true,
    preserveViewport: true
  });
  
    const request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING'
    };
    directionsService.route(request, function(result, status) {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);
      }
    });
    directionsRenderer.setMap(map);

}
exports.drawPolyLine = (data, map) => {
  // Daw Polyline
const polyLines = [
  { lat: data.jobOriginLatitude, lng: data.jobOriginLongitude },
  { lat: data.jobDestinationLatitude, lng: data.jobDestinationLongitude },
];

var path = new window.google.maps.Polyline({
  path: polyLines,
  geodesic: true,
  strokeColor: "#009adb",
  strokeOpacity: 1.0,
  strokeWeight: 6,
});

path.setMap(map);
}
exports.setMarkerBounds = (data, map) => {
  const bounds = new window.google.maps.LatLngBounds();
  bounds.extend({
    lat: data.jobOriginLatitude,
    lng: data.jobOriginLongitude
  });
  bounds.extend({
    lat: data.jobDestinationLatitude,
    lng: data.jobDestinationLongitude
    
  });
 
  bounds.extend({
    lat: data.driver.latitude,
    lng: data.driver.longitude
  }
  );
  map && map.fitBounds(bounds, 20);

}

exports.getInfoWindowString = (name) => `
    <div>
      <div style="font-size: 16px;">
        ${name}
      </div>
    </div>`;
