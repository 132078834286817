import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Tracking from "./tracking/Tracking";
import TrakingDetail from "./traking-detail/TrakingDetail";
import TripAssistance from "./trip-assistance/TripAssistance";
import Error from "./error/Error";

export default function App(props) {


  return (
    <Router>
      <Switch>
      <Route path="/tripassistance/:id" component={TripAssistance} />
        <Route path="/details/:id" component={TrakingDetail} />
        <Route path="/expire" component={Error} />
        <Route path="/:id" component={Tracking}  />
        <Route path="**" component={Error} />
      </Switch>
    </Router>
  );
}
