import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import GoogleMap from "../components/google-component/GoogleMap";
import Moment from "react-moment";
import { DEFAULT_CENTER, SERVER_URL } from "../common/common_var";
import { Transition } from "@headlessui/react";
import driverMarker from "../assets/marker.png";
import pickupMarker from "../assets/pickup.png";
import dropoffMarker from "../assets/dropoff.png";
import {
  addMarker,
  addMarkerInfo,
  setMarkerBounds,
  drawRoute,
} from "../common/utils";
import mapStyle from "../components/google-component/GoogelMapStyle";

const handleApiLoaded = (map, maps, data) => {
  const markers = [];
  const infowindows = [];
  // setStyle Map
  const styledMapType = new window.google.maps.StyledMapType(mapStyle, {
    name: "Styled Map",
  });
  map.mapTypes.set("styled_map", styledMapType);
  map.setMapTypeId("styled_map");

  // Add all Marker
  const originLatLn = { lat: data.jobOriginLatitude, lng: data.jobOriginLongitude };
  const destinationLatLn = { lat: data.jobDestinationLatitude, lng: data.jobDestinationLongitude };
  const driverLatLn = { lat: data.driver.latitude, lng: data.driver.longitude };
  addMarker(markers, map, maps, originLatLn, "Pickup", pickupMarker);
  addMarker(markers, map, maps, destinationLatLn, "Drop Off", dropoffMarker);
  addMarker(markers, map, maps, driverLatLn, "Driver", driverMarker);
  // add All Marker Info
  addMarkerInfo(infowindows, maps, data.jobOriginAddress);
  addMarkerInfo(infowindows, maps, data.jobDestinationAddress);
  addMarkerInfo(infowindows, maps, data.driver.displayName + ` <span  style="font-size: 12px; display: block; margin-top: 2px;" >${data.driver.contactNumber}</span>`);
  // Set polyLine
  // drawPolyLine(data,map);
  // drawRoute between two points
  const start = { lat: data.jobOriginLatitude, lng: data.jobOriginLongitude };
  const end = { lat: data.jobDestinationLatitude, lng: data.jobDestinationLongitude };
  drawRoute(data, map, start, end);
  // Set Map Bounds
  setMarkerBounds(data, map);

  markers.forEach((marker, i) => {
    marker.addListener("click", () => {
      infowindows[i].open(map, marker);
    });
  });
};

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isShow: true,
    };
    // console.log(props);
  }

  componentDidMount() {
    this.fetchJobDetails();
    this.intervalId = setInterval(() => {
      this.fetchJobDetails();
    }, 5000);
  }

  componentWillUnmount() {
    this.setState({
      data: {},
      isShow: true
    })
    clearInterval(this.intervalId);
  }

  fetchJobDetails() {
    fetch(
      `${SERVER_URL}/api/assigns/tracking/${this.props.match.params.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data && data.driver && !data.message) {
          this.setState({ data });
          if (data.jobStatus === "finished") {
            this.props.history.push(`/details/${this.props.match.params.id}`);
          } else if (data.jobStatus === "cancelled") {
            this.props.history.push("/expire");
          }
        } else {
          this.props.history.push("/expire");
        }
      })
      .catch(error => {
        this.props.history.push("/expire");
        throw error;
      });
  }

  cancelTripCall(params) {
    // console.log('cancelled Params', params);
    // return false;
    const putMethod = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        _id: params._id,
        company: params.company
      })
    }
    fetch(
      `${SERVER_URL}/api/assigns/markcompleted`, putMethod
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.status === 'cancelled') {
          this.props.history.push("/expire");
        }
      })
      .catch(error => {
        this.props.history.push("/expire");
        throw error;
      });

  }
  cancelTrip(params) {
    if (window.confirm('Are you sure you want to cancel this trip?')) {
      this.cancelTripCall(params);
    }
  }

  render() {
    const { data, isShow } = this.state;

    return (
      !isEmpty(data) && (
        <section className="h-screen w-full relative">
          {!isEmpty(data) && (
            <GoogleMap
              defaultZoom={8}
              defaultCenter={DEFAULT_CENTER}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                handleApiLoaded(map, maps, data)
              }
            ></GoogleMap>
          )}
          <Transition
            show={isShow}
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute bottom-0 left-0 right-0 max-w-3xl mx-auto w-full p-4">
              <div className="shadow-lg bg-white w-full rounded-2xl">
                <div className="rounded-t-2xl bg-gray-50 flex justify-between px-3 py-2">
                  <div
                    className="text-theme1 font-bold text-xs"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.jobStatus === 'started' ? 'Member Onboard' : data?.jobStatus}
                  </div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => this.setState({ isShow: !isShow })}
                  >
                    <div className="text-theme1 font-bold text-xs">Hide</div>
                    <div className="ml-1">
                      <svg
                        className="h-5 w-5 text-theme1 stroke-current stroke-2 animate-pulse"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="py-3 divide-y">
                  <div className="flex justify-between items-center px-3 py-2">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0">
                        <img
                          src={data?.driver?.profileImageURL}
                          className="h-10 w-10 rounded-full object-cover"
                          alt=""
                        ></img>
                      </div>
                      <div className="ml-1">
                        <div className="text-xs text-gray-900 font-bold">
                          {data?.driver?.displayName}
                        </div>
                        <div className="text-xs text-gray-400">
                          {data?.driver?.driverCar},{" "}
                          {data?.driver?.driverCarColor}{" "}
                          {data?.driver?.driverCarModel}
                        </div>
                        <div className="text-xs text-gray-400">
                          {data?.company?.displayName}
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-200 rounded-full p-1">
                      <a
                        href={"tel:" + data?.driver?.contactNumber}
                        className="h-9 w-9 rounded-full bg-theme1 flex justify-center items-center"
                      >
                        <div>
                          <svg
                            className="h-5 w-5 text-white stroke-current stroke-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="leading-tight text-xs py-2 px-3">
                    <div className="text-gray-900">
                      Pickup (
                      <span>
                        <Moment format="MM/DD/YYYY">
                          {data?.scheduleTime}
                        </Moment>
                      </span>{" "}
                      <span>
                        {" "}
                        <Moment format="hh:mm">{data?.scheduleTime}</Moment>
                      </span>
                      )
                    </div>
                    <div className="text-gray-900">
                      {data?.jobOriginAddress}
                    </div>
                  </div>
                  <div className="leading-tight text-xs py-2 px-3">
                    <div className="text-gray-900">
                      Appointment At (
                      <span>
                        <Moment format="hh:mm">{data?.appointmentTime}</Moment>
                      </span>
                      )
                    </div>
                    <div className="text-gray-900">
                      {data?.jobDestinationAddress}
                    </div>
                  </div>
                  { data?.jobStatus === 'onway' || data?.jobStatus === 'arrived' ?
                    <div className="py-2 px-3 flex justify-center" >
                      <a href="#" onClick={() => this.cancelTrip(data)} className="p-4 bg-red-600 rounded-lg text-white border w-full text-center">Cancel Your Trip</a>
                    </div>
                    : ''
                  }
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={!isShow}
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              onClick={() => this.setState({ isShow: !isShow })}
              className="shadow-2xl cursor-pointer absolute right-1 bottom-32 h-11 w-11 rounded-full bg-white mr-2 flex items-center justify-center"
            >
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="info"
                className="h-5 w-5 text-theme1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
              >
                <path
                  fill="currentColor"
                  d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"
                />
              </svg>
            </div>
          </Transition>
        </section>
      )
    );
  }
}

export default Tracking;
